import React, { ReactNode } from "react";
import { Link as GatsbyLink } from 'gatsby';
import { Link, Flex, Heading } from "theme-ui";

const HeaderMenuLink: React.FC<{href: string, big?: boolean, children: ReactNode}> = ({href, big, children}) =>
    <Link
        variant={big ? 'headerMenuBig' : 'headerMenu'}
        as={GatsbyLink}
        //@ts-expect-error
        to={href}
    >
        { children }
    </Link>

const TopMenu = () =>
    <Flex sx={{
        position: 'absolute',
        top: '0.5em',
        margin: ['0.5em', '1em']
    }}>
        <HeaderMenuLink href='/' big>Kevin Balz</HeaderMenuLink>
        <HeaderMenuLink href='/#AboutMe'>About Me</HeaderMenuLink>
        <HeaderMenuLink href="/#Showcase">Showcase</HeaderMenuLink>
    </Flex>

export default TopMenu;
